import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import MenuSection from "../components/MenuSection";
import menuData from "../utils/menuData";

const BusinessLunchPage = () => {
  return (
    <>
      <Helmet>
        <title>Business Lunch at Ambrosia - South Melbourne's Premier Corporate Dining</title>
        <meta
          name="description"
          content="Elevate your business meetings with Ambrosia's exclusive business lunch options in South Melbourne. Perfect for client meetings, team lunches, and corporate events."
        />
      </Helmet>
      <section aria-labelledby="business-lunch-label" className="section about text-center" id="business-lunch">
        <div className="container">
          <div className="about-content">
            <p className="label-2 section-subtitle" id="business-lunch-label">
              Corporate Dining
            </p>
            <h2 className="headline-1 section-title">Business Lunch at Ambrosia</h2>
            <p className="section-text">
              Elevate your business meetings with our specially curated lunch menu. Perfect for impressing clients or
              treating your team to a delightful midday meal.
            </p>
            <div className="contact-label">Book Through Email</div>
            <Link className="body-1 contact-number hover-underline" to="mailto:msvfoods@gmail.com">
              msvfoods@gmail.com
            </Link>
            <Link className="btn btn-primary" to="/reservation">
              <span className="text text-1">Make a Reservation</span>
              <span aria-hidden="true" className="text text-2">
                Make a Reservation
              </span>
            </Link>
          </div>
          <figure className="about-banner">
            <img
              alt="Business lunch at Ambrosia"
              className="w-100"
              height="570"
              loading="lazy"
              src="/assets/images/business-lunch.webp"
              width="570"
            />
          </figure>
        </div>
      </section>
      <section className="section service bg-black-10 text-center" id="business-lunch-menu">
        <div className="container">
          <p className="label-2 section-subtitle">Our Menu</p>
          <h2 className="headline-1 section-title">Business Lunch Offerings</h2>
        </div>
      </section>
      <MenuSection title="Entrées" subtitle="Start with Excellence" items={menuData.entrees.slice(0, 4)} />
      <MenuSection title="Mains" subtitle="Culinary Masterpieces" items={menuData.mains.slice(0, 4)} />
      <MenuSection title="Desserts" subtitle="Sweet Conclusions" items={menuData.desserts.slice(0, 3)} />
    </>
  );
};

export default BusinessLunchPage;
