import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// Add more structured data for better SEO
const structuredData = {
  "@context": "https://schema.org",
  "@type": "Restaurant",
  name: "Ambrosia Restaurant",
  description:
    "Group dining and events venue in South Melbourne offering private dining rooms and corporate catering services",
  address: {
    "@type": "PostalAddress",
    streetAddress: "274 Park Street",
    addressLocality: "South Melbourne",
    addressRegion: "VIC",
    postalCode: "3205",
    addressCountry: "AU",
  },
  priceRange: "$$",
  servesCuisine: "Modern Indian",
  maximumAttendeeCapacity: 30,
};

const GroupBookingPage = () => {
  return (
    <>
      <Helmet>
        <title>Group Bookings & Private Events South Melbourne | Ambrosia Restaurant</title>
        <meta
          name="description"
          content="Book Ambrosia's private dining room for groups up to 30 guests. Perfect for corporate events, celebrations & parties in South Melbourne. Custom menus & professional service."
        />
        <meta
          name="keywords"
          content="group bookings south melbourne, private dining room, corporate events, party venue melbourne, group restaurant booking"
        />
        <meta property="og:title" content="Group Bookings & Private Events South Melbourne | Ambrosia Restaurant" />
        <meta
          property="og:description"
          content="Book Ambrosia's private dining room for groups up to 30 guests. Perfect for corporate events, celebrations & parties in South Melbourne."
        />
        <meta property="og:image" content="/assets/images/about-banner.jpg" />
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
      <section aria-labelledby="group-booking-label" className="section about text-center" id="group-booking">
        <div className="container">
          <div className="about-content">
            <p className="label-2 section-subtitle" id="group-booking-label">
              Group Experiences
            </p>
            <h2 className="headline-1 section-title">Group Bookings at Ambrosia</h2>
            <p className="section-text">
              Whether you're planning a corporate event, a family celebration, or a gathering with friends, Ambrosia is
              the perfect venue for your group booking in South Melbourne.
            </p>
            <div className="contact-label">Book Through Email</div>
            <Link className="body-1 contact-number hover-underline" to="mailto:msvfoods@gmail.com">
              msvfoods@gmail.com
            </Link>
            <Link className="btn btn-primary" to="/reservation">
              <span className="text text-1">Make an Enquiry</span>
              <span aria-hidden="true" className="text text-2">
                Make an Enquiry
              </span>
            </Link>
          </div>
          <figure className="about-banner">
            <img
              alt="Group dining at Ambrosia"
              className="w-100"
              height="570"
              loading="lazy"
              src="/assets/images/about-banner.jpg"
              width="570"
            />
          </figure>
        </div>
      </section>
      <section className="section service bg-black-10 text-center" id="booking-options">
        <div className="container">
          <p className="label-2 section-subtitle">Our Offerings</p>
          <h2 className="headline-1 section-title">Group Booking Options</h2>
          <ul className="grid-list" style={{ marginTop: "200px" }}>
            <li>
              <div className="service-card">
                <a href="#private-dining" className="has-before hover:shine">
                  <figure className="card-banner img-holder" style={{ "--width": 285, "--height": 336 }}>
                    <img
                      src="/assets/images/about-banner.jpg"
                      width="285"
                      height="336"
                      loading="lazy"
                      alt="Private Dining"
                      className="img-cover"
                    />
                  </figure>
                </a>
                <div className="card-content">
                  <h3 className="title-4 card-title">
                    <a href="tel:+61480246220">Private Dining Room</a>
                  </h3>
                  <p className="label-2 card-text">Up to 30 guests</p>
                </div>
              </div>
            </li>
            <li>
              <div className="service-card">
                <a href="#catering" className="has-before hover:shine">
                  <figure className="card-banner img-holder" style={{ "--width": 285, "--height": 336 }}>
                    <img
                      src="/assets/images/catering.webp"
                      width="285"
                      height="336"
                      loading="lazy"
                      alt="On-site Catering"
                      className="img-cover"
                    />
                  </figure>
                </a>
                <div className="card-content">
                  <h3 className="title-4 card-title">
                    <a href="tel:+61480246220">On-site Catering</a>
                  </h3>
                  <p className="label-2 card-text">For larger events</p>
                </div>
              </div>
            </li>
            {/* link our business lunch page, similar to above group offerings */}
            <li>
              <div className="service-card">
                <Link to="/business-lunch" className="has-before hover:shine">
                  <figure className="card-banner img-holder" style={{ "--width": 285, "--height": 336 }}>
                    <img
                      src="/assets/images/business-lunch.webp"
                      width="285"
                      height="336"
                      loading="lazy"
                      alt="Business Lunch"
                      className="img-cover"
                    />
                  </figure>
                </Link>
                <div className="card-content">
                  <h3 className="title-4 card-title">
                    <Link to="/business-lunch">Business Lunch</Link>
                  </h3>
                  <p className="label-2 card-text">Perfect for corporate events</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default GroupBookingPage;
